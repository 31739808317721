<template>
  <div>
    <validation-observer ref="contactForm">
      <b-form @submit.prevent="sendMessage()">
        <b-row>

          <!-- first name -->
          <!-- <b-col cols="12">
                  <b-form-group
                    label="First Name"
                    label-for="vi-first-name"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="vi-first-name"
                        placeholder="First Name"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col> -->

          <!-- email -->
          <!-- <b-col cols="12">
                  <b-form-group
                    label="Email"
                    label-for="vi-email"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="vi-email"
                        type="email"
                        placeholder="Email"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col> -->

          <!-- mobile -->
          <!-- <b-col cols="12">
                  <b-form-group
                    label="Mobile"
                    label-for="vi-mobile"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="vi-mobile"
                        type="number"
                        placeholder="Mobile"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col> -->

          <!-- password -->
          <b-col cols="12">
            <b-form-group
              label=""
              label-for="vi-message"
            >
              <validation-provider
                #default="{ errors }"
                name="message"
                rules="required"
              >
                <b-form-textarea
                  id="vi-message"
                  v-model="message"
                  placeholder="Message..."
                  :state="errors.length > 0 ? false:null"
                  rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- reset and submit -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              block
              type="submit"
            >
              Send Message
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormTextarea, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    hide: { type: Function },
  },
  data() {
    return {
      required,
      message: '',
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.profile
    },
    businessProfileDetail: {
      get() {
        return this.$store.state.businessProfile.single_profile
      },
      set(value) {
        return value
      },
    },
  },
  methods: {
    sendMessage() {
      this.$refs.contactForm.validate().then(success => {
        if (success) {
          const data = {
            member_id: this.userData.id,
            business_profile_id: this.businessProfileDetail.id,
            type: 'email',
            message: this.message,

          }
          this.$store.dispatch('businessProfile/contactUs', data)
          this.message = ''
          this.hide()
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
