<template>
  <div>
    <ServicesTopBar />
    <Banner />
    <Details />
    <SiteFooter />
  </div>
</template>

<script>
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import Banner from '../components/services/Banner.vue'
import SiteFooter from '../components/site/SiteFooter.vue'
import Details from '../components/services/Details.vue'

export default {
  components: {
    ServicesTopBar, SiteFooter, Banner, Details,
  },
  data() {
    return {

    }
  },
  mounted() {
    this.getBusinessDetail()
  },
  methods: {
    getBusinessDetail() {
      const id = JSON.parse(localStorage.getItem('gts_profile_id'))
      this.$store.dispatch('businessProfile/getSingleBusinessProfile', id)
    },
  },
}
</script>

<style scoped>

</style>
