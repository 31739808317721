<template>
  <validation-observer ref="recomForm">
    <b-form @submit.prevent="recommendBusiness()">
      <b-row>

        <!-- first name -->
        <b-col cols="12">
          <b-form-group
            label="Select contact"
            label-for="vi-member"
          >
            <validation-provider
              #default="{ errors }"
              name="contact"
              rules="required"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>

                <b-form-select
                  id="vi-contact"
                  v-model="selected"
                  :state="errors.length > 0 ? false:null"
                  :options="contactOptions"
                  @change="selectData"
                />

              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col cols="12">
          <b-form-group
            label="Email"
            label-for="vi-email"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-email"
                v-model="email"
                type="email"
                placeholder="Email"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- mobile -->
        <b-col cols="12">
          <b-form-group
            label="Mobile"
            label-for="vi-mobile"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SmartphoneIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-mobile"
                v-model="phone"
                type="text"
                placeholder="Mobile"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- password -->
        <b-col cols="12">
          <b-form-group
            label="Message"
            label-for="vi-message"
          >
            <validation-provider
              #default="{ errors }"
              name="message"
              rules="required"
            >
              <b-input-group class="input-group-merge">
                <!-- <b-input-group-prepend is-text>
              <feather-icon icon="Edit2Icon" />
            </b-input-group-prepend> -->
                <b-form-textarea
                  id="vi-message"
                  v-model="message"
                  placeholder="Message..."
                  :state="errors.length > 0 ? false:null"
                  rows="3"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- reset and submit -->
        <b-col
          cols="12"
          style="display:flex; justify-content: flex-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            type="submit"
          >
            Send
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BFormSelect, BCol, BButton, BFormGroup, BFormInput, BFormTextarea, BForm, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    hide: { type: Function },
    id: { type: Number },
    name: { type: String },
  },
  data() {
    return {
      selected: null,
      email: '',
      phone: '',
      message: '',
      required,
    }
  },
  computed: {
    contacts() {
      return this.$store.state.contact.contacts
    },
    contactOptions() {
      return this.contacts.map(item => {
        const data = { value: item.id, text: `${item.full_name}` }
        return data
      })
    },
    userData() {
      return this.$store.state.user.profile
    },
  },
  mounted() {
    this.getContacts()
  },
  methods: {
    getContacts() {
      this.$store.dispatch('contact/getUserContacts', this.userData.id)
    },
    recommendBusiness() {
      this.$refs.recomForm.validate().then(success => {
        if (success) {
          const data = {
            business_profile_id: this.id,
            contact_id: this.selected,
            member_id: this.userData.id,
            message: this.message,
          }
          this.$store.dispatch('recommend/sendRecommendation', data)
          this.hide()
          this.emptyData()
        }
      })
    },
    emptyData() {
      const dom = this
      setTimeout(() => {
        dom.email = ''
        dom.selected = null
        dom.phone = ''
        dom.message = ''
      }, 200)
    },
    selectData(item) {
      const singleContact = this.contacts.filter(elm => elm.id === item)[0]
      this.email = singleContact.email
      this.phone = singleContact.phone
    },
  },
}
</script>

<style scoped>

</style>
