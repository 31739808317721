<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="service_details_section_view">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- <div class="service_view">

            <div class="row">
              <div class="col-md-6">
                <div class="item">
                  <h4 class="mb-1">
                    Services Offered
                  </h4>
                  <div class="">

                    <b-badge
                      v-for="(item, index) in businessProfileDetail.services"
                      :key="index"
                      variant="light-success"
                      class="mr-1 mb-1"
                    >
                      {{ item.service.name }}
                    </b-badge>
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="item">
                  <div class="row">
                    <div class="col-md-12">
                      <h4>Areas Served</h4>
                      <div class="row">
                        <div
                          v-for="(item, index) in service_areas"
                          :key="index"
                          class="col-md-12"
                        >
                          <div
                            v-if="item.item && item.item.length > 0"
                            class="service_card"
                          >
                            <div class="service_item">
                              <span>{{ item.name }}: </span>
                              <b-badge
                                v-for="(elm, keys) in item.item"
                                :key="keys"
                                variant="light-secondary"
                                class=""
                              ><feather-icon
                                icon="MapPinIcon"
                                class="mr-25"
                              /> {{ elm.county.name }}</b-badge>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div> -->
          <!-- <div class="striegth_line" /> -->
          <Reviews />
          <div class="striegth_line" />
          <div class="business_details_section">
            <div class="row">
              <div class="col-md-6 mb-2">
                <h4>Business Detials</h4>
                <span>{{ businessProfileDetail.name }}</span><br>
                <span>{{ businessProfileDetail.address1 }} <br> {{ businessProfileDetail.city }},  {{ businessProfileDetail.str_state }} {{ businessProfileDetail.zip_code }}</span>
                <br><br>

                <span>{{ businessProfileDetail.phone }}</span>
                <br>
                <span>{{ businessProfileDetail.email }}</span>

                <br><br>
                <span @click="openWebsite" style="color:blue; cursor: pointer; font-weight: bold;"> {{ businessProfileDetail.website }}</span>
                <br><br>

              </div>

              <div class="col-md-6 mb-2">
                <h4>Contact Person</h4>
                <span>{{ businessProfileDetail.contact_name }}, {{ businessProfileDetail.contact_title }}</span>
                <br>
                <span>{{ businessProfileDetail.contact_phone }}</span>
                <br>
                <span>{{ businessProfileDetail.contact_email }}</span>

              </div>
            </div>
          </div>

          <div class="striegth_line" />
          <div class="open_hours">
            <h4 class="mb-3">
              Business Hours
            </h4>
            <div class="row">
              <div
                class="col-md-12"
                style="margin-bottom:5px"
              >
                <div
                  v-for="(item, index) in businessProfileDetail.business_hours"
                  :key="index"
                  class="row"
                >
                  <div class="col-md-2">
                    <h5>{{ item.day && item.day.name }}</h5>
                  </div>
                  <div class="col-md-4">
                    <h5>{{ tConvert(item.business_hours_start) }} - {{ tConvert(item.business_hours_end) }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="striegth_line" />
          <div class="overview_box">
            <h4>About <span class="overview_business_name">{{ businessProfileDetail.name }}</span></h4>
            <div
              class="details_content_view"
              :class="is_collaps ? 'is_details_show' : ''"
              v-html="businessProfileDetail.description"
            />
            <!-- <span
              class="read_more_button"
              @click="showDetailContent"
            >{{ is_collaps ? 'Show Less' : 'Read More' }}</span> -->
          </div>
          <!-- <div class="map_section">
            <h4 class="mb-3">
              Location & Maps
            </h4>
            <l-map
              :zoom="zoom"
              :center="center"
            >
              <l-tile-layer :url="url" />
              <l-marker :lat-lng="markerLatLng" />
              <l-circle
                :lat-lng="circle.center"
                :radius="circle.radius"
                :color="circle.color"
              />
            </l-map>
            <div class="direction_view">
              <feather-icon icon="MapPinIcon" />
              <span> {{ businessProfileDetail.address1 }}, {{ businessProfileDetail.city }} {{ businessProfileDetail.zip_code }} <span class="direction_text">(Direction)</span></span>
            </div>
          </div> -->

        </div>
        <!-- <div class="col-md-4">
          <b-card class="contact_form">
            <div class="contact_title_box">
              <div class="icon_box">
                <feather-icon
                  icon="MailIcon"
                  size="20"
                />
              </div>
              <div class="contact_text">
                <h5>Send a message to  <feather-icon icon="CornerRightDownIcon" /></h5>
                <span>{{ businessProfileDetail.name }}</span>
              </div>
            </div>

          </b-card>

        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import {
  LMap, LTileLayer, LCircle, LMarker,
} from 'vue2-leaflet'
import {
  BCard, BRow, BCol, BFormGroup, BButton, BFormTextarea, BForm, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { Icon } from 'leaflet'
import Reviews from './Reviews.vue'
import 'leaflet/dist/leaflet.css'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
    BBadge,
    Reviews,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [47.313220, -1.319482],
      circle: {
        center: [47.413220, -1.0482],
        radius: 4500,
        color: '#EA5455',
      },
      markerLatLng: [47.313220, -1.319482],
      is_collaps: false,
    }
  },
  computed: {
    businessProfileDetail: {
      get() {
        return this.$store.state.businessProfile.single_profile
      },
      set(value) {
        return value
      },
    },
    userData() {
      return this.$store.state.user.profile
    },
    service_areas() {
      const listOfVACounty = this.businessProfileDetail?.service_areas?.filter(item => item.state.abbreviation === 'VA')
      const listOfDCCounty = this.businessProfileDetail?.service_areas?.filter(item => item.state.abbreviation === 'DC')
      const listOfMDCounty = this.businessProfileDetail?.service_areas?.filter(item => item.state.abbreviation === 'MD')
      const data = [
        { name: 'VA', item: listOfVACounty },
        { name: 'DC', item: listOfDCCounty },
        { name: 'MD', item: listOfMDCounty },
      ]

      return data
    },
  },
  watch: {
    '$store.state.businessProfile.single_profile': {
      handler(old, newValue) {
        const dom = this
        if (newValue !== undefined) {
          setTimeout(() => {
            dom.center = [dom.businessProfileDetail.lat, dom.businessProfileDetail.lng]
            dom.markerLatLng = [dom.businessProfileDetail.lat, dom.businessProfileDetail.lng]
            dom.circle.center = [dom.businessProfileDetail.lat, dom.businessProfileDetail.lng]
          }, 100)
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getBusinessDetail()
    // console.log(this.service_areas)
  },
  methods: {
    getBusinessDetail() {
      const id = JSON.parse(localStorage.getItem('gts_profile_id'))
      this.$store.dispatch('businessProfile/getSingleBusinessProfile', id)
    },
    showDetailContent() {
      this.is_collaps = !this.is_collaps
    },
    openWebsite() {
      const link = this.businessProfileDetail.website
      const regex = /https/i

      if (regex.test(link)) {
         window.open(`${link}`, '_blank')
      } else {
        window.open(`https://${link}`, '_blank')
      }
    },
    tConvert(timeString) {
      const timeStr = `${timeString}:00`
      const timeString12hr = new Date(`1970-01-01T${timeStr}Z`)
        .toLocaleTimeString('en-US',
          {
            timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric',
          })
      return timeString12hr
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
  },
}
</script>

<style lang="scss" scoped>
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
