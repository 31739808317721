<template>
  <!-- v-lazy:background-image="getImage(businessProfileDetail.cover)" -->
  <section class="service_details_section" :style="getImage(businessProfileDetail.cover)">
    <div class="container" style="height: 100%">
      <div class="split_bg_section">
        <div class="top__split">
          <div class="share_box">
            <div class="share_item" @click="$router.go(-1)">
              <feather-icon icon="ArrowLeftIcon" size="24" />
            </div>
          </div>
          
          <div class="bottom_split">
          <div class="title_view">
            <!-- <div class="category_view">
              <span></span>
            </div> -->
            <h3 class="title_text">{{ businessProfileDetail.name }} - {{ businessProfileDetail.str_category }}</h3>
          </div>
        </div>

          <div class="share_box"
            v-if="user_type === 'member' || userData.is_vendor === true">
            <div v-b-tooltip.hover.top="galleries && galleries.length > 0 ? 'Gallery' : 'No Gallery Available'" class="share_item" @click="index = 0">
              <feather-icon icon="LayersIcon" size="24" />
            </div>
            <div v-b-tooltip.hover.top="'Send Message'" class="share_item" @click="showMessageModal">
              <feather-icon icon="MailIcon" size="24" />
            </div>
            <div v-b-tooltip.hover.top="businessProfileDetail.favorite ? 'Remove from favourite' : 'Add to favourite'"
              class="share_item" @click="addToFavourite(businessProfileDetail)">
              <img v-if="businessProfileDetail.favorite" :src="FavoriteIcon" alt="sort">
              <img v-else :src="FavoriteIcon2" alt="sort">
            </div>
            <div v-b-tooltip.hover.top="'Recommend Business'" class="share_item" @click="showReferarModal">
              <feather-icon icon="CornerUpRightIcon" size="24" />
            </div>

            <div v-b-tooltip.hover.top="'Add Review'"
              v-if="user_type !== 'staff' && user_type !== 'manager'"
              class="share_item" @click="showReviewModal">
              <img :src="AddReview" alt="sort">
            </div>
          </div>
        </div>
        <div class="details_section_view mt-3">
        <div class="bottom_split service__view mt-2">
          <div class="title_view service_title">
            <div class="category_view">
              <span>Service Offer</span>
            </div>
            <div class="banner_service__offter">
              <b-badge v-for="(item, index) in businessProfileDetail.services" :key="index" variant="secondary"
                class="mr-1 mb-1">
                {{ item.service.name }}
              </b-badge>
            </div>
          </div>
          <div class="title_view">
            <div class="category_view">
              <span>Area Service</span>
            </div>
            <div class="banner_service__offter">
              <div class="row">
                <div v-for="(item, index) in service_areas" :key="index" class="col-md-12">
                  <div v-if="item.item && item.item.length > 0" class="service_card">
                    <div class="service_item">
                      <span>{{ item.name }}: </span>
                      <b-badge v-for="(elm, keys) in item.item" :key="keys" variant="secondary" class=""><feather-icon
                          icon="MapPinIcon" class="mr-25" /> {{ elm.county.name }}</b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</div>



      </div>
    </div>
    <b-modal id="review-modal" hide-footer>
      <template #modal-title>
        Write a review: <small class="text-info">{{ businessProfileDetail.name }}</small>
      </template>
      <b-card-text>
        <div class="review_content" :class="is_customer_service === false ?  'is_review_content_hidden' : ''">
          <h4 class="mb-1">
            Customer Service
          </h4>
          <p class="mb-2">
            How would you rate the responsiveness of our customer service team?
          </p>
          <b-form-group label="">
            <b-form-radio-group v-model="customer_service" :options="customer_services" name="customer_service"
              class="app_radio_box" stacked @change="checkCustomerService" />
          </b-form-group>
        </div>

        <div class="review_content" :class="is_quality_service === false ? 'is_review_content_hidden' : ''">
          <h4 class="mb-1">
            Quality of Service
          </h4>
          <p class="mb-2">
            How satisfied were you with the overall quality of service you received?
          </p>
          <b-form-group label="">
            <b-form-radio-group v-model="quality" :options="quality_services" name="quality_service"
              class="app_radio_box" stacked @change="checkQualityService" />
          </b-form-group>
        </div>

        <div  class="review_content" :class="is_money_value === false ? 'is_review_content_hidden' : ''">
          <h4 class="mb-1">
            Value for Money
          </h4>
          <p class="mb-2">
            Were you satisfied with the level of customer service provided for the price paid?
          </p>
          <b-form-group label="">
            <b-form-radio-group v-model="pricing" :options="money_values" name="money_value" class="app_radio_box"
              stacked @change="checkMoneyValue" />
          </b-form-group>
        </div>

        <div class="review_content" :class="is_timeless === false ? 'is_review_content_hidden' : ''">
          <h4 class="mb-1">
            Timeliness
          </h4>
          <p class="mb-2">
            Was the service delivered on time?
          </p>
          <b-form-group label="">
            <b-form-radio-group v-model="timeliness" :options="timeless" name="timeless" class="app_radio_box"
              stacked @change="checkTimeliness" />
          </b-form-group>
        </div>
        <div  class="review_content" :class="!is_recommend ? 'is_review_content_hidden' : ''">
          <h4 class="mb-1">
            Recommend
          </h4>
          <p class="mb-2">
            Would you recommend this service to others based on the quality of service you received?
          </p>
          <b-form-group label="">
            <b-form-radio-group v-model="recommend" :options="recommends" name="recommend" class="app_radio_box"
              stacked @change="checkRecommend" />
          </b-form-group>
        </div>

        <div v-if="is_rating_enable" class="review_content">
          <h4 class="mb-1 text-center">
            Ratings
          </h4>
          <p class="text-center">
            How will you rate this service?
          </p>

          <div class="text-center">
            <feather-icon icon="FrownIcon" size="20" class="rating_emoji text-danger" @click="setRating" />
            <b-form-rating id="rating-lg" v-model="value" size="lg" class="raiting_box" inline variant="warning"
              @change="checkRating" />
          </div>
        </div>

        <div v-if="is_write_comment" class="">
          <validation-observer ref="reviewForm">
            <validation-provider #default="{ errors }" name="comment" rules="required">
              <b-form-textarea id="textarea-default" v-model="review_message" placeholder="Write some comment..."
                :state="errors.length > 0 ? false : null" rows="4" />
              <small class="text-danger">{{ errors[0] }}</small>
              <br>
            </validation-provider>
          </validation-observer>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" class="mt-2"
            @click="is_edit_mode ? updateReview() : postReview()">
            {{ is_edit_mode ? 'Save Changes' : 'Post Review' }}
          </b-button>
        </div>

        <div v-if="is_edit_mode" class="edit-next-button">
          <b-button v-if="edit_page > 0" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" class="mt-2"
            @click="prevPage">
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button v-if="edit_page < 6" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" class="mt-2"
            @click="nextPage">
            <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal id="referal-modal" hide-footer>
      <template #modal-title>
        Recomend Business : <small class="text-info">{{ businessProfileDetail.name }}</small>
      </template>
      <b-card-text>
        <RecomendForm :name="businessProfileDetail.name" :id="businessProfileDetail.id" :hide="hide" />
      </b-card-text>
    </b-modal>

    <b-modal id="message-modal" hide-footer>
      <template #modal-title>
        <div class="contact_text">
          <h5>Send an email to <feather-icon icon="CornerRightDownIcon" /></h5>
          <span>{{ businessProfileDetail.name }}</span>
        </div>
      </template>
      <b-card-text>
        <SendMessage :name="businessProfileDetail" :id="businessProfileDetail.id" :hide="hide" />
      </b-card-text>
    </b-modal>
    <Tinybox v-if="galleries && galleries.length > 0" :images="galleries" :index="index" @change="(i) => { index = i }" />
  </section>
</template>
<script>
import {
  VBTooltip, BModal, VBModal, BCardText, BBadge, BFormRadioGroup, BFormGroup, BFormRating, BButton, BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import bg from '../../../assets/images/bg/banner.jpeg'
import RecomendForm from './RecomendForm.vue'
import Tinybox from 'vue-tinybox'
import Ripple from 'vue-ripple-directive'
import SendMessage from './SendMessage.vue'
import FavoriteIcon from '../../../assets/images/icons/heart-deep.png'
import FavoriteIcon2 from '../../../assets/images/icons/heart-plain.png'
import AddReview from '../../../assets/images/icons/add-review.png'
import moment from 'moment'
import FrownIcon from '../../../assets/images/icons/fown.png'

export default {
  components: {
    BModal,
    BCardText,
    BFormRating,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormRadioGroup,
    BFormGroup,
    BFormTextarea,
    BFormTextarea,
    BButton,
    BCardText,
    RecomendForm,
    Tinybox,
    SendMessage
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      FavoriteIcon,
      FavoriteIcon2,
      AddReview,
      bg,
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Simple Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      index: null,
      FrownIcon,
      selected: '',
      edit_page: 0,
      is_edit_mode: false,
      required,
      value: 0,
      review_message: '',
      is_customer_service: true,
      is_quality_service: false,
      is_money_value: false,
      is_timeless: false,
      is_recommend: false,
      is_rating_enable: false,
      is_write_comment: false,
      customer_services: [
        { text: 'Excellent', value: 'Excellent' },
        { text: 'Good', value: 'Good' },
        { text: 'Fair', value: 'Fair' },
        { text: 'Poor', value: 'Poor' },
        { text: 'Very poor', value: 'Very poor' },
      ],
      quality_services: [
        { text: 'Very satisfied', value: 'Very satisfied' },
        { text: 'Satisfied', value: 'Satisfied' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Dissatisfied', value: 'Dissatisfied' },
        { text: 'Very dissatisfied', value: 'Very dissatisfied' },
      ],
      money_values: [
        { text: 'Yes, I was satisfied', value: 'Yes, I was satisfied' },
        { text: 'Somewhat satisfied', value: 'Somewhat satisfied' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Somewhat dissatisfied', value: 'Somewhat dissatisfied' },
        { text: 'No, I was not satisfied:', value: 'No, I was not satisfied' },
      ],
      timeless: [
        { text: 'Yes, the service was delivered on time', value: 'Yes, the service was delivered on time' },
        { text: 'Somewhat on time', value: 'Somewhat on time' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Somewhat late', value: 'Somewhat late' },
        { text: 'No, the service was not delivered on time', value: 'No, the service was not delivered on time' },
      ],
      recommends: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
      ],
      customer_service: '', 
      quality: '', 
      pricing: '', 
      recommend: '', 
      timeliness: '',
      review_id: null,
      is_reivew_content_show: [],
      user_type: "",
    }
  },
  computed: {
    businessProfileDetail: {
      get() {
        return this.$store.state.businessProfile.single_profile
      },
      set(value) {
        return value
      },
    },
    galleries() {
      if(this.businessProfileDetail?.gallery < 1) return;
      const getImages = this.businessProfileDetail?.gallery?.map(item => `${process.env.VUE_APP_STORAGE_PATH + item.path}`)
      return getImages
    },
    userData() {
      return this.$store.state.user.profile
    },
    service_areas() {
      const listOfVACounty = this.businessProfileDetail?.service_areas?.filter(item => item.state.abbreviation === 'VA')
      const listOfDCCounty = this.businessProfileDetail?.service_areas?.filter(item => item.state.abbreviation === 'DC')
      const listOfMDCounty = this.businessProfileDetail?.service_areas?.filter(item => item.state.abbreviation === 'MD')
      const data = [
        { name: 'VA', item: listOfVACounty },
        { name: 'DC', item: listOfDCCounty },
        { name: 'MD', item: listOfMDCounty },
      ]

      return data
    },
  },
  mounted() {
    this.user_type = localStorage.getItem('gts_user_type')
  },
  methods: {
    getImage(image) {
      if (image !== undefined) {
        return `background-image: url(${process.env.VUE_APP_STORAGE_PATH + image}) !important`
      }
      return image
    },
    // getImage(image) {
    //   if (image !== undefined) {
    //     return process.env.VUE_APP_STORAGE_PATH + image
    //   }
    //   return image
    // },
    showReferarModal() {
      this.$bvModal.show('referal-modal')
    },
    showMessageModal() {
      this.$bvModal.show('message-modal')
    },
    showReviewModal() {
      
      if(this.businessProfileDetail.member_review.status === 'reported'
       || this.businessProfileDetail.member_review.status === 'canceled' || this.businessProfileDetail.member_review.status === 'under review') {
         return this.$swal({
        title: 'Oops!',
        text: ' You have already added a review to this business and your review has been reported, please contact the admin',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
        },
        buttonsStyling: false,
      })
 
      }
      const reviewer = this.businessProfileDetail.reviews.filter(item => item.reviewer.id === this.userData.id)
      if (reviewer.length > 0) {
        const review = reviewer[0]
        this.review_id = review.id
        this.customer_service = review.content.customer_service
        this.quality = review.content.quality
        this.pricing = review.content.pricing
        this.recommend = review.content.recommend
        this.timeliness = review.content.timeliness
        this.review_message = review.review
        this.value = review.rating
        this.is_edit_mode = true
        this.$swal({
          title: `You reviewed this service at ${this.date(review.created_at)}`,
          text: 'Do you want to update this review?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Update Review',
          customClass: {
            confirmButton: 'btn btn-outline-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$bvModal.show('review-modal')
          }
        })
      } else {
        this.$bvModal.show('review-modal')
      }
    },
    hide() {
      this.$bvModal.hide('referal-modal')
      this.$bvModal.hide('message-modal')
      this.$bvModal.hide('review-modal')
      this.emptyData()
    },
    addToFavourite(item) {
      const data = {
        member_id: this.userData.id,
        business_profile_id: item.id,
        favorite: !item.favorite,
      }

      this.$store.dispatch('businessProfile/addSingleProfileToFavourite', data)
    },
    nextPage() {
      this.edit_page += 1
      if (this.edit_page === 1) {
        this.is_customer_service = false
        this.is_quality_service = true
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 2) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = true
        this.is_timeless = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 3) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 4) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 5) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = true
        this.is_write_comment = false
      }

      if (this.edit_page === 6) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = true
      }
    },
    prevPage() {
      this.edit_page -= 1
      if (this.edit_page === 0) {
        this.edit_page = 0
        this.is_customer_service = true
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }
      if (this.edit_page === 1) {
        this.is_customer_service = false
        this.is_quality_service = true
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 2) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = true
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 3) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = true
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 4) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = true
        this.is_rating_enable = false
        this.is_write_comment = false
      }

      if (this.edit_page === 5) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = true
        this.is_write_comment = false
      }

      if (this.edit_page === 6) {
        this.is_customer_service = false
        this.is_quality_service = false
        this.is_money_value = false
        this.is_timeless = false
        this.is_recommend = false
        this.is_rating_enable = false
        this.is_write_comment = true
      }
    },
    postReview() {
      this.$refs.reviewForm.validate().then(success => {
        if (success) {
          const data = {
            business_profile_id: this.businessProfileDetail.id,
            member_id: this.userData.id,
            rating: this.value,
            review: this.review_message,
            content: {
              customer_service: this.customer_service, 
              quality: this.quality, 
              pricing: this.pricing, 
              recommend: this.recommend, 
              timeliness: this.timeliness,
            },

          }
          this.$store.dispatch('businessProfile/postReview', data)
          this.hide()
        }
      })
    },
    updateReview() {
      this.$refs.reviewForm.validate().then(success => {
        if (success) {
          const data = {
            id: this.review_id,
            business_profile_id: this.businessProfileDetail.id,
            member_id: this.userData.id,
            rating: this.value,
            review: this.review_message,
            content: {
              customer_service: this.customer_service, 
              quality: this.quality, 
              pricing: this.pricing, 
              recommend: this.recommend, 
              timeliness: this.timeliness,
            },

          }
          this.$store.dispatch('businessProfile/updateReview', data)
          this.hide()
          this.edit_page = 0
          this.is_edit_mode = false
        }
      })
    },
    date(date) {
      return moment(date).format('ddd DD-MMM-YY hh:mm a')
    },
    emptyData() {
      this.value = 0
      this.review_message = ''
      this.is_customer_service = true
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_rating_enable = false
      this.is_write_comment = false
      this.customer_service = ''
      this.quality = ''
      this.money_value = ''
      this.timeliness = ''
    },
    checkCustomerService(item) {
      this.is_customer_service = false
      this.is_quality_service = true
      this.is_money_value = false
      this.is_timeless = false
      this.customer_service = item
      this.edit_page = 0
    },
    checkQualityService(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = true
      this.is_timeless = false
      this.quality = item
      this.edit_page = 1
    },
    checkMoneyValue(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = true
      this.pricing = item
      this.edit_page = 2
    },
    checkTimeliness(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = true
      this.timeliness = item
      this.edit_page = 3
    },
    checkRecommend(item) {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = false
      this.is_rating_enable = true
      this.recommend = item
      this.edit_page = 4
    },
    checkRating() {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_recommend = false
      this.is_rating_enable = false
      this.is_write_comment = true
      this.edit_page = 5
    },
    setRating() {
      this.is_customer_service = false
      this.is_quality_service = false
      this.is_money_value = false
      this.is_timeless = false
      this.is_rating_enable = false
      this.is_recommend = false
      this.value = 0
      this.is_write_comment = true
      this.edit_page = 5
    },
    showReviewContent(id) {
      if (this.is_reivew_content_show.includes(id)) {
        this.is_reivew_content_show = this.is_reivew_content_show.filter(item => item !== id)
      } else {
        this.is_reivew_content_show.push(id)
      }
    },
  },
}
</script>
