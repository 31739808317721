<template>
  <div class="section_review_page site_item_view_box">

    <div class="review_heading_view">
      <div class="review_heading">
        <h4> Review {{ businessProfileDetail.total_reviews > 0 ? '(' + businessProfileDetail.total_reviews + ')' : '' }} </h4>

        <div class="star_view">

          <b-form-rating
            v-if="businessProfileDetail.average_rating > 0"
            v-model="businessProfileDetail.average_rating"
            variant="warning"
            inline
            no-border
            readonly
            class="raiting_box"
          />
          <span class="text-dark">{{ businessProfileDetail.average_rating > 0 ? businessProfileDetail.average_rating + ' out of 5': ': No reviews' }}</span>
        </div>
      </div>
      <!-- <b-button
        v-if="userData.user_info && userData.user_info.user_type !== 'staff' && userData.user_info && userData.user_info.user_type !== 'manager'"
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-success"
        class="ml-2"
        @click="showModal"
      >
        Write a Review
      </b-button> -->
    </div>
    <div
      v-for="(review, index) in businessProfileDetail.reviews"
      :key="index"
      class="review_content_display"
    >
      <div class="review_profile_view">
        <b-avatar
          v-if="review.reviewer.avatar === null || review.reviewer.avatar === ''"
          variant="success"
          :text="getUserText(review.reviewer.first_name, review.reviewer.last_name)"
          size="50"
        />
        <b-avatar
          v-else
          :src="getImage(review.reviewer.avatar)"
          size="50"
        />
        <div class="profile_details">
          <h5>{{ review.reviewer && review.reviewer.first_name }} {{ review.reviewer && review.reviewer.last_name }}, <span>{{ review.reviewer && review.reviewer.profession }}</span></h5>
          <div class="raiting_stars">
            <!-- <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            >
            <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            >
            <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            >
            <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            >
            <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            > -->
            <b-form-rating
              v-model="review.rating"
              variant="warning"
              inline
              no-border
              readonly
              class="raiting_box"
            />
          </div>
        </div>
      </div>
      <p class="review_display_text">
        {{ review.review }}
      </p>
      <div
        class="extra_review_content"
      >
        <!-- <h5>Extra Review</h5> -->
        <div class="row mb-1">
          <div class="col-md-2 review-content">
            <feather-icon
              icon="ChevronsRightIcon"
              class="mr-1"
            />
            <span class="review-content-heading">Customer Service</span>
          </div>
          <div class="col-md-4">
            {{ review.content.customer_service }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-2 review-content">
            <feather-icon
              icon="ChevronsRightIcon"
              class="mr-1"
            />
            <span class="review-content-heading">Pricing</span>
          </div>
          <div class="col-md-4">
            {{ review.content.pricing }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-2 review-content">
            <feather-icon
              icon="ChevronsRightIcon"
              class="mr-1"
            />
            <span class="review-content-heading">Timeliness</span>
          </div>
          <div class="col-md-4">
            {{ review.content.timeliness }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-2 review-content">
            <feather-icon
              icon="ChevronsRightIcon"
              class="mr-1"
            />
            <span class="review-content-heading">Quality</span>
          </div>
          <div class="col-md-4">
            {{ review.content.quality }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md-2 review-content">
            <feather-icon
              icon="ChevronsRightIcon"
              class="mr-1"
            />
            <span class="review-content-heading">Recommendation</span>
          </div>
          <div class="col-md-4">
            {{ review.content.recommend }}
          </div>

          <div class="col-md-12">
            <b-button
              v-if="user_type !== 'staff' && user_type !== 'manager' && review.reviewer.id !== userData.id"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              class="mt-2"
              @click="showReportReview(review)"
            >
              Report Review
            </b-button>
          </div>
        </div>
      </div>

      <!-- <span
        v-if="!is_reivew_content_show.includes(review.id)"
        class="review_read_more_button"
        @click="showReviewContent(review.id)"
      >Read More</span>
      <span
        v-if="is_reivew_content_show.includes(review.id)"
        class="review_read_more_button"
        @click="showReviewContent(review.id)"
      >Hide</span> -->
    </div>

    <div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="success"
        class=""
        @click="showAllReviews"
      >
        View All Reviews
      </b-button>

      <b-modal
        id="all-reviews-modal"
        title="All Reviews"
        hide-footer
        size="huge"
      >
        <div class="row">
          <div
            v-for="(review, index) in business_reviews"
            :key="index"
            class="col-md-6"
          >
            <div
              class="review_content_display"
              style="border: 1px solid #f2f2f2 !important; padding: 10px; border-radius:10px; "
            >
              <div class="review_profile_view">
                <b-avatar
                  v-if="review.reviewer.avatar === null || review.reviewer.avatar === ''"
                  variant="success"
                  :text="getUserText(review.reviewer.first_name, review.reviewer.last_name)"
                  size="50"
                />
                <b-avatar
                  v-else
                  :src="getImage(review.reviewer.avatar)"
                  size="50"
                />
                <div class="profile_details">
                  <h5>{{ review.reviewer && review.reviewer.first_name }} {{ review.reviewer && review.reviewer.last_name }}, <span>{{ review.reviewer && review.reviewer.profession }}</span></h5>
                  <div class="raiting_stars">
                    <!-- <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            >
            <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            >
            <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            >
            <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            >
            <img
              v-if="review.rating === 0"
              :src="FrownIcon"
              class="raiting-image"
            > -->
                    <b-form-rating
                      v-model="review.rating"
                      variant="warning"
                      inline
                      no-border
                      readonly
                      class="raiting_box"
                    />
                  </div>
                </div>
              </div>
              <p class="">
                {{ review.review }}
              </p>
              <div
                class="extra_review_content"
              >
                <!-- <h5>Extra Review</h5> -->
                <div class="row mb-1">
                  <div class="col-md-5 review-content">
                    <feather-icon
                      icon="ChevronsRightIcon"
                      class="mr-1"
                    />
                    <span class="review-content-heading">Customer Service</span>
                  </div>
                  <div class="col-md-7">
                    {{ review.content.customer_service }}
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-md-5 review-content">
                    <feather-icon
                      icon="ChevronsRightIcon"
                      class="mr-1"
                    />
                    <span class="review-content-heading">Pricing</span>
                  </div>
                  <div class="col-md-7">
                    {{ review.content.pricing }}
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-md-5 review-content">
                    <feather-icon
                      icon="ChevronsRightIcon"
                      class="mr-1"
                    />
                    <span class="review-content-heading">Timeliness</span>
                  </div>
                  <div class="col-md-7">
                    {{ review.content.timeliness }}
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-md-5 review-content">
                    <feather-icon
                      icon="ChevronsRightIcon"
                      class="mr-1"
                    />
                    <span class="review-content-heading">Quality</span>
                  </div>
                  <div class="col-md-7">
                    {{ review.content.quality }}
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-md-5 review-content">
                    <feather-icon
                      icon="ChevronsRightIcon"
                      class="mr-1"
                    />
                    <span class="review-content-heading">Recommendation</span>
                  </div>
                  <div class="col-md-7">
                    {{ review.content.recommend }}
                  </div>

                  <div class="col-md-12">
                    <b-button
                      v-if="user_type !== 'staff' && user_type !== 'manager' && review.reviewer.id !== userData.id"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-success"
                      class="mt-2"
                      @click="showReportReview(review)"
                    >
                      Report Review
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </b-modal>
    </div>
    <b-modal
      id="report-review-modal"
      title="Write your report"
      hide-footer
    >
      <validation-observer ref="reportReview">
        <validation-provider
          #default="{ errors }"
          name="report"
          rules="required"
        >
          <b-form-textarea
            id="textarea-default"
            v-model="report"
            placeholder="write your report"
            :state="errors.length > 0 ? false:null"
            rows="4"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <br>
        </validation-provider>
      </validation-observer>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-success"
        class="mt-2"
        @click="reportReview"
      >
        Send
      </b-button>
    </b-modal>

  </div>
</template>

<script>
import {
  BAvatar, BFormRating, BButton, BModal, VBModal, BFormRadioGroup, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
export default {
  components: {
    BAvatar,
    BFormRating,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BModal,
    BFormRadioGroup,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      report: '',
      required,
      user_type: '',
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.profile
    },
    businessProfileDetail: {
      get() {
        return this.$store.state.businessProfile.single_profile
      },
      set(value) {
        return value
      },
    },
    business_reviews() {
      return this.$store.state.review.business_profile_reviews
    },
  },
  mounted() {
    this.user_type = localStorage.getItem('gts_user_type')
  },
  methods: {
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
    showReportReview(item) {
      this.review_id = item.id
      this.$bvModal.show('report-review-modal')
    },
    showAllReviews() {
      this.$store.dispatch('review/getBusinessReviews', { page: 1, per_page: 10, business_profile_id: this.businessProfileDetail.id })
      this.$bvModal.show('all-reviews-modal')
    },
    onReviewPageChange(page) {
      return this.$store.dispatch('review/getBusinessReviews', { page, per_page: 10, business_profile_id: this.businessProfileDetail.id })
    },
    reportReview() {
      this.$refs.reportReview.validate().then(success => {
        if (success) {
          const data = {
            business_profile_id: this.businessProfileDetail.id,
            content: this.report,
            id: this.review_id,
          }
          this.$store.dispatch('businessProfile/reportReview', data)
          this.$bvModal.hide('report-review-modal')
          this.report = ''
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
